
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.amp-infographic-preview::v-deep {
    position: relative;
    background-color: $black;
    border: 1px solid transparent;
    width: 100%;
    height: calc(45vw / 9 * 16);
    min-height: 350px !important;
    margin-top: 44px;
    padding: 0;
    transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    .actions {
        position: relative;
        background-color: $black;
        margin-top: -44px;
        margin-left: -1px;
        width: calc(100% + 2px);
        z-index: 2;
    }

    &.zoom {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        height: 100% !important;
        width: 100% !important;
        max-width: 100% !important;
        max-height: calc(100vh - 44px);
        min-height: -webkit-fill-available !important;
        min-width: -webkit-fill-available !important;

        border: none;

        .actions {
            margin-left: 0;
            width: 100%;
        }
    }

    &.light {
        background-color: $grey-light-darker;

        .actions {
            background-color: lighten($boulder, 18%);
        }
    }

    .slide-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transition: transform 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        transform-origin: top left;
    }

    .progress-indicator {
        transform: translate(-50%, -50%);
    }

    .video-box {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
